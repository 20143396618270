import * as React from "react"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SiteHero from "../components/SiteHero"
import AngleRight from "../icons/angle-right.svg"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  const firstPost = posts[0]

  const images = firstPost.frontmatter.featuredImages.map(image =>
    getImage(image)
  )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="hero-container">
        <SiteHero
          title={firstPost.frontmatter.title}
          href={firstPost.fields.slug}
          date={firstPost.frontmatter.date}
          images={images}
        />
      </div>
      <main className="content-wrapper">
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                  </header>
                  <section>
                    <p itemProp="description">
                      {post.frontmatter.description || post.excerpt}
                    </p>
                    <Link
                      to={post.fields.slug}
                      itemProp="url"
                      className="post-list-item--read-more"
                    >
                      Read more
                      <AngleRight className="button-icon" />
                    </Link>
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImages {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
