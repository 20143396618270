import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import AngleRight from "../icons/angle-right.svg"

interface ProvidedProps {
  title: string
  date: string
  href: string
  images: IGatsbyImageData[]
}

const SiteHero = ({ title, date, href, images }: ProvidedProps) => {
  return (
    <>
      <div className="site-hero--background">
        <div className="site-hero--background-container-inner">
          <div className="rectangle-1"></div>
          <div className="rectangle-2"></div>
        </div>
        <div className="red-ellipse"></div>
      </div>
      <div className="site-hero--container">
        <div className="content-wrapper">
          <div className="site-hero--popup">
            <div className="site-hero--left-pane">
              <div className="flex-grow">
                <h1>{title}</h1>
                <p className="site-hero--popup-date">{date}</p>
              </div>
              <div>
                <Link to={href} className="site-hero--read-more">
                  Read more <AngleRight className="button-icon" />
                </Link>
              </div>
            </div>
            <div className="site-hero--right-pane">
              <div className="site-hero--image-mask">
                <GatsbyImage
                  image={images[0]}
                  alt="Hero image"
                  objectFit="contain"
                  className="site-hero--image-2"
                />
                <GatsbyImage
                  image={images[1]}
                  alt="Hero image"
                  objectFit="contain"
                  className="site-hero--image-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SiteHero
